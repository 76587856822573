.bonds {
  padding: 30px 0;
  margin-top: 20px;

  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    &__title {
      font-size: 36px;
      font-weight: 700;
      line-height: 40px;
      letter-spacing: -0.02em;
      margin-bottom: 16px;
      color: var(--dark-blue);
    }
    &__desc {
      max-width: 800px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.1px;
      color: var(--light-blue);
    }
  }

  .content {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
}

@media (max-width: 1200px) {
  .bonds {
    padding: 15px;
  }
}
@media (max-width: 767.9px) {
  .bonds {
    padding-bottom: 50px;
  }
}
@media (max-width: 655px) {
  .bonds {
    padding-top: 0;

    .header {
      &__title {
        margin-bottom: 0;

        font-size: 24px;
        line-height: 31px;
        letter-spacing: -0.02em;
      }
    }
  }
}