.menu {
  ul {
    display: flex;
    flex-direction: row;

    li {
      margin-right: 45px;
    }
  }

  a {
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0;
  }
}