.promotion {
  width: 100%;
  padding: 30px 0;

  background: url('../../../shared/images/bg-promo.webp') no-repeat center;
  background-size: cover;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: end;
    gap: 64px;
  }

  .left_block {
    max-width: 624px;

    .title {
      font-size: 36px;
      font-weight: 700;
      line-height: 47px;
      letter-spacing: -0.01em;
      text-align: left;
      color: var(--light-black);
      margin-bottom: 24px;

      span {
        color: var(--gold-prim);
      }

    }
    .desc {
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: -0.1px;
      text-align: left;
      color: var(--light-black);
      margin-bottom: 32px;
    }
    .btn {
      margin-bottom: 80px;
    }

    .partners {
      margin-top: 80px;

      &__title {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.1px;
        text-align: left;
        margin-bottom: 18px;
      }
      &__icons {
        display: flex;
        gap: 10px;
      }
    }
  }
  .right_block {
    max-width: 528px;
    max-height: 528px;

    img {
      max-width: 100%;
      height: auto;
    }
  }
}

@media (max-width: 1440px) {
  .promotion {
    width: 100vw;
    &__container {
      padding: 30px;
    }
  }
}
@media (max-width: 1200px) {
  .promotion {
    gap: 20px;
    padding: 15px 0;

    &__container {
      gap: 30px;
    }

    .left_block {
      .partners {
        margin-top: 30px;
      }
    }
  }
}
@media (max-width: 1180px) {
  .promotion {
    gap: 15px;

    &__container {
      align-items: start;
    }

    .left_block {
      .title {
        font-size: 2.8vw;
        line-height: 3.5vw;
        margin-bottom: 2vw;
      }
      .desc {
        font-size: 1.8vw;
        line-height: 3vw;
        margin-bottom: 2vw;
      }
      .partners {
        &__icons {
          flex-wrap: wrap;
          margin-top: 2vw;
        }
      }
    }
  }
}
@media (max-width: 870px) {
  .promotion {
    &__container {
      gap: 15px;
    }

    .left_block {
      width: 100%;
      .partners {
        &__icons {
          img {
            max-width: 20vw;
            max-height: 20vw;
          }
        }
      }
    }
  }
}

@media (max-width: 767.9px) {
  .promotion {

    background: url('../../../shared/images/truck-mobile.webp') no-repeat center;
    background-size: cover;

    &__container {
      flex-direction: column;
      align-items: start;
      gap: 0;
      padding: 5px;
    }

    .left_block {
      padding: 15px;

      .title {
        margin-bottom: 25px;
        font-size: 26px;
        line-height: 40px;
        color: var(--white);
      }
      .desc {
        margin-bottom: 30px;
        font-size: 18px;
        line-height: 26px;
        color: var(--white);
      }
    }
    .right_block {
      padding: 15px;

      .desc_mobile {
        display: flex;
        flex-direction: column;
        gap: 30px;

        color: var(--white);

        &__count {
          margin-bottom: 10px;
          font-size: 36px;
          font-weight: 600;
          line-height: 44px;
          letter-spacing: -0.02em;
        }
        &__text {
          font-size: 16px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: -0.1px;
        }
      }
    }
  }
}
@media (max-width: 655px) {
  .promotion {
    .left_block {
      padding: 15px;
      max-width: calc(100% - 30px);

      .title {
        font-size: 26px;
        line-height: 34px;
        letter-spacing: -0.02em;
      }
    }
  }
}
@media (max-width: 500px) {
  .promotion {
    &__container {
      .left_block {
        .title {
          p {
            &:first-child {
              span {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 400px) {
  .promotion {
    .left_block {
      button {
        width: 100%;
      }
    }
  }
}