.our_advantages {
  padding: 30px 0;
  margin-top: 20px;

  .title {
    align-self: center;
    margin-bottom: 40px;

    font-size: 32px;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0;
    text-align: left;
  }
}

@media (max-width: 1200px) {
  .our_advantages {
    padding: 5px;
  }
}
@media (max-width: 767.9px) {
  .our_advantages {
    padding-bottom: 50px;
  }
}
@media (max-width: 655px) {
  .our_advantages {
    .title {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.02em;
    }
  }
}