.button {
  width: 235px;
  height: 46px;
  border-radius: 6px;
  gap: 6px;
  box-shadow: var(--box-shadow);
  background: none;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 1.5px;
  text-align: center;
  text-transform: uppercase;
  color: var(--white);
  cursor: pointer;
  //transition: var(--transition);

  &:hover {
    //transition: var(--transition);
  }
  &.default {
    background: var(--gold-prim);
    border: 3px solid var(--gold-prim);
    color: var(--light-black);
    &:hover {
      background: var(--dark-gold-prim);
      border-color: var(--dark-gold-prim);
    }
  }
  &.variant2 {
    border: 3px solid var(--gold-prim);
    &:hover {
      background: var(--gold-prim);
      color: var(--light-black);
    }
  }
  &.variant3 {
    border: 3px solid var(--light-black);
    &:hover {
      background: var(--gold-prim);
      color: var(--light-black);
    }
  }
}