.hov_invest {
  width: 100%;
  padding: 64px 0;
  margin-top: 20px;

  background: url('../../../shared/images/bg-invest.webp') no-repeat center;
  background-size: cover;

  &__container {
    justify-content: space-between;
  }
  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    &__title {
      font-size: 36px;
      font-weight: 700;
      line-height: 40px;
      letter-spacing: -0.02em;
      margin-bottom: 16px;
      color: var(--dark-blue);
    }
    &__desc {
      max-width: 800px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.1px;
      color: var(--light-blue);
    }
  }
  .content {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    padding: 0 30px;
    margin-top: 60px;

    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__num {
        width: 42px;
        min-width: 42px;
        height: 40px;
        min-height: 40px;
        border-radius: 50%;
        background-color: var(--gold-prim);
        color: var(--white);
        text-align: center;
        font-size: 24px;
        line-height: 40px;
        margin-bottom: 10px ;
      }
      &__desc {
        font-size: 22px;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: -0.01em;
        text-align: center;
      }
    }
  }
}

@media (max-width: 1440px) {
  .hov_invest {
    width: 100vw;
  }
}
@media (max-width: 1280px) {
  .hov_invest {
    padding: 15px 0;

    .content {
      gap: 20px;
      margin-top: 30px;
      margin-bottom: 30px;

      .item {
        &__desc {
          font-size: 1.8vw;
          line-height: 2.5vw;
        }
      }
    }
  }
}
@media (max-width: 767.9px) {
  .hov_invest {
    width: calc(100% - 30px);

    .content {
      flex-wrap: wrap;
      .item {
        width: 100%;
        flex-direction: row;
        align-items: start;

        &__num {
          margin-bottom: 0;
        }
        &__desc {
          width: 100%;
          align-items: center;
          padding-top: 5px;
          margin-left: 20px;

          font-size: 16px;
          line-height: 22px;
          text-align: start;

          br {
            display: none;
          }
        }
      }
    }
  }
}
@media (max-width: 655px) {
  .hov_invest {
    background: none;

    .header {
      &__title {
        font-size: 24px;
        line-height: 32px;
      }
      &__desc {
        font-size: 16px;
        line-height: 21px;
      }
    }
  }
}