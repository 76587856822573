.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 25px 20px;
  border-radius: 5px;
  gap: 16px;

  background: linear-gradient(0deg, var(--white), var(--white)),
  linear-gradient(0deg, var(--neutral), var(--neutral));
  box-shadow: var(--box-shadow);
  border: 1px solid var(--neutral);

  &__img {
    width: 40px;
    height: 28px;
    margin-bottom: 16px;
  }
  &__title {
    max-width: 288px;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: var(--dark-blue);
  }
  &__desc {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: var(--light-blue);

    p {
      &:last-child {
        margin-top: 20px;
      }
    }
  }
}
.cards {
  width: 100%;
  display: flex;
  gap: 15px;

  .card {
    @extend .card;
  }
}
.cards_slider {
  max-width: 400px;

  .card {
    @extend .card;

    max-width: 343px;
    min-height: 376px;
    text-align: start;

    &__img {
      margin-bottom: 26px;
    }
    &__title {
      margin-bottom: 10px;
    }
    &__desc {
      p {
        &:last-child {
          margin-top: 30px;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .cards {
    .card {
      &__img {
        width: 30px;
        height: 28px;
        margin-bottom: 16px;
      }
      &__title {
        font-size: 1.6vw;
        line-height: 1.6vw;
      }
      &__desc {
        font-size: 1.4vw;
        line-height: 1.4vw;
      }
    }
  }
}
@media (max-width: 500px) {
  .cards_slider {
    max-width: 360px;
    .card {
      max-width: 300px;
    }
  }
}
@media (max-width: 400px) {
  .cards_slider {
    max-width: 300px;

    .card {
      max-width: 250px;
    }
  }
}