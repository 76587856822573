.investors_results {
  padding: 30px 0;
  margin-top: 20px;
  margin-bottom: 30px;

  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    text-align: center;

    &__title {
      font-size: 36px;
      font-weight: 700;
      line-height: 40px;
      letter-spacing: -0.02em;
      margin-bottom: 16px;
      color: var(--dark-blue);
    }
    &__desc {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.1px;
      color: var(--light-blue);
    }
  }
  .content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 64px;
    margin-top: 64px;

    &__img {
      max-width: 617px;
      max-height: 584px;

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}

@media (max-width: 1440px) {
  .investors_results {
    .content {
      gap: 30px;
    }
  }
}
@media (max-width: 1280px) {
  .investors_results {
    .content {
      margin-top: 30px;
      &__img {
        flex: 0.5;
      }
      &__table {
        flex: 0.5;
      }
    }
  }
}
@media (max-width: 767.9px) {
  .investors_results {
    width: calc(100% - 30px);
    background-color: var(--white2);

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      margin-bottom: 30px;
    }
  }
}
@media (max-width: 655px) {
  .investors_results {
    .header {
      &__title {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.02em;
      }
    }
  }
}