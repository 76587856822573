.header {
  .layout {
    border-bottom: 1px solid var(--neutral);
  }

  &--fixed {
    z-index: 3;
    width: 100%;
    position: fixed;
  }
  &__left_icon {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 18px;
    left: 25px;
  }
}

@media (max-width: 767.9px) {
  .header {
    .layout {
      border-bottom: none;

      div {
        justify-content: center;
        gap: 20px;
      }
    }
  }
}