.table {
  width: 100%;
  height: 100%;
  border-spacing: 0;

  thead {
    tr > th {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0;
      color: var(--light-blue1);
      padding: 30px;
    }
  }
  tbody {
    tr > td {
      padding: 0 30px 35px;
    }

    .name {
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0;
      color: var(--grey);
    }
    .payout {
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0;
      color: var(--dark-blue1);

      border-left: 1px solid var(--light-grey1);
      border-right: 1px solid var(--light-grey1);

    }
    .income {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      color: var(--white);

      &__value {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px 8px;
        border-radius: 4px;
        background: var(--green);

        span {
          font-size: 10px;
          color: var(--light-green);
          padding-right: 5px;
        }
      }
    }
  }

  .bordered {
    border-left: 1px solid var(--light-grey1);
    border-right: 1px solid var(--light-grey1);
  }
}

@media (max-width: 1200px) {
  .table {
    thead {
      tr > th {
        font-size: 1.6vw;
        line-height: 1.6vw;
        padding-bottom: 15px;
      }
    }
    tbody {
      tr > td {
        padding: 0 15px 15px;
      }

      .name {
        font-size: 1.6vw;
        line-height: 1.6vw;
      }
      .payout {
        font-size: 1.6vw;
        line-height: 1.6vw;
      }
      .income {
        font-size: 1.6vw;
      }
    }
  }
}
@media (max-width: 767.9px) {
  .table {
    padding: 10px 0;
    border-radius: 9.59px;

    background-color: var(--white);

    thead {
      tr > th {
        font-size: 14px;
        line-height: 24px;
        padding-bottom: 15px;
      }
    }
    tbody {
      tr > td {
        padding: 0 15px 20px;
      }

      .name {
        font-size: 14px;
        line-height: 22px;
      }
      .payout {
        font-size: 16px;
        line-height: 22px;
      }
      .income {
        font-size: 14px;
        line-height: 14px;
      }
    }
  }
}
@media (max-width: 655px) {
  .table {
    thead {
      tr > th {
        padding: 0 15px 20px 15px;
        text-align: center;

        &:first-child {
          text-align: left;
        }
        &:last-child {
          text-align: right;
        }
      }
    }
    tbody {
      .payout {
        border: none;
      }
    }

    .bordered {
      border: none;
    }
  }
}
@media (max-width: 400px) {
  .table {
    tbody {
      tr > td {
        padding: 0 15px 20px;
      }

      .name {
        font-size: 14px;
        line-height: 22px;
      }
      .payout {
        font-size: 14px;
        line-height: 22px;
      }
      .income {
        font-size: 14px;
        line-height: 14px;
      }
    }
  }
}