.complete_MainContainer {
  padding: 0 10px;
}

@media only screen and (max-width: 400px) {
  .complete_LeftContainer {
    padding: 0 15px !important;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .complete_MainContainer {
    padding: 0;
  }
  .complete_HeaderContainer {
    width: 100%;
    display: flex;
    padding: 10px 0px 16px 0px;

    gap: 10px;
    align-self: stretch;
    background: #393939;

    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  .complete_HeaderContent {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1268px;
    margin: 0 auto;
  }
  .complete_HeaderLogo {
    display: flex;
    justify-content: center;
    img {
      width: 158px;
      height: 48px;
      flex-shrink: 0;
    }
  }
  .complete_ContentContainer {
    display: flex;
    width: 100%;
    max-width: 1268px;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
  }
  .complete_ContentContainer {
    margin-top: 0;
    margin-bottom: 44px;
    display: flex;
    align-items: flex-start;
    gap: 32px;
    flex-wrap: wrap;
    border-radius: 0;
    border: none;
    background: #fefefe;
    box-shadow: none;
    overflow: hidden;
  }
  .complete_LeftContainer {
    display: flex;
    width: 100%;
    max-width: 618px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 0 30px;
    background: #fff;
  }
  .complete_LeftTop {
    display: flex;
    width: 100%;
    padding: 16px 30px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    order: 2;
  }
  .complete_Head {
    color: var(--Text-Black-01, var(--text-Black, #0e0e0e));
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .complete_Desc {
    display: flex;
    flex-direction: column;

    color: #fff;

    color: var(--Text-Black-01, var(--text-Black, #0e0e0e));
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .complete_TicketContainer {
    display: flex;
    padding: 18px;
    flex-direction: column;
    align-items: flex-start;
    order: 3;
  }
  .complete_TicketImg {
    position: relative;
    z-index: 0;
    img {
      width: 278px;
      height: 268px;
    }
  }
  .complete_TicketContent {
    padding: 12.925px 30px;
    position: absolute;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 14.064px;
    z-index: 1;
  }
  .complete_TicketHead {
    color: #040404;

    font-family: Roboto;
    font-size: 22.182px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .complete_TicketNumber {
    color: var(--gold-prim, #fcc13f);
    font-family: Roboto;
    font-size: 44.364px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  .complete_TicketRegisteredContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10.548px;
  }
  .complete_TicketRegistered {
    color: #686666;

    font-family: Roboto;
    font-size: 18.485px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .complete_TicketCallHoursContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .complete_TicketRegisteredTime {
    color: #686666;

    font-family: Roboto;
    font-size: 18.485px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .complete_TicketCallHoursHead {
    color: #040404;

    font-family: Roboto;
    font-size: 22.182px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .complete_TicketCallHours {
    color: #f00;

    font-family: Roboto;
    font-size: 18.485px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .complete_LeftBottom {
    width: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    img {
      width: 188px;
      height: 57px;
    }
  }
  .complete_RightContainer {
    display: flex;
    width: 100%;
    max-width: 100%;
    padding: 40px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
  }
  .complete_RightTopBox {
    display: none;

    width: 100%;
    padding: 16px 32px;
    align-items: flex-start;
    gap: 64px;

    border-radius: none;
    background: var(--gold-prim, #fcc13f);
    box-shadow: none;
  }
  .complete_LeftTopBox {
    display: flex;

    order: 1;
    width: 100%;
    padding: 16px 32px;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: none;
    background: var(--gold-prim, #fcc13f);
    box-shadow: none;
  }
  .complete_TopBoxName {
    color: #070707;

    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }
  .complete_TopBoxNumbers {
    color: #070707;

    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .complete_TopBoxValue {
    color: #070707;

    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .complete_TopBoxTime {
    display: flex;
    align-items: right;
    justify-content: end;
    color: #070707;

    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .complete_MiddleText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
    gap: 8px;

    color: var(--Text-Grey-01, #868585);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .complete_Video {
    width: 100%;
    max-width: 586px;
    height: auto;
    flex-shrink: 0;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .complete_BottomAlert {
    display: flex;
    width: 100%;
    max-width: 559px;
    padding: 12px 16px;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    margin-top: 30px;
    box-sizing: border-box;

    background: #ff2424;
  }
  .complete_BottomLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 7px;
    align-self: stretch;
  }
  .complete_BottomHead {
    color: #fff;

    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  .complete_BottomDesc {
    color: #fff;

    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .complete_BottomRight {
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
  }
}

@media only screen and (min-width: 600px) and (max-width: 768px) {
  .complete_MainContainer {
    width: 100%;
    display: flex;

    gap: 10px;
    align-self: stretch;
    border-bottom: 1px solid var(--Neutral-600, #eaebf0);
    background: #393939;

    padding: 0;
  }
  .complete_HeaderContainer {
    display: flex;
    margin: 0 auto;

    width: 100%;
    display: flex;
    padding: 10px 0px 16px 0px;

    gap: 10px;
    align-self: stretch;
    background: #393939;

    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  .complete_HeaderContent {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
  }
  .complete_HeaderLogo {
    display: flex;
    justify-content: center;
    img {
      width: 158px;
      height: 48px;
      flex-shrink: 0;
    }
  }

  .complete_ContentContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
  }
  .complete_ContentContainer {
    margin-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 0;
    border: none;
    background: #fefefe;
    box-shadow: none;
    overflow: hidden;
  }
  .complete_LeftContainer {
    display: flex;
    width: 100%;
    max-width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 0 30px;
    background: #fff;
  }
  .complete_LeftTop {
    display: flex;
    width: 100%;
    max-width: 100%;
    padding: 16px 30px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    order: 2;
  }
  .complete_Head {
    color: var(--Text-Black-01, var(--text-Black, #0e0e0e));
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-left: 0;
  }
  .complete_Desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px 0 0 0;

    color: #fff;

    color: var(--Text-Black-01, var(--text-Black, #0e0e0e));
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .complete_TicketContainer {
    display: flex;
    padding: 18px;
    flex-direction: column;
    align-items: center;
    gap: 12.925px;
    order: 3;
  }
  .complete_TicketImg {
    position: relative;
    z-index: 0;
    img {
      width: 278px;
      height: 268px;
    }
  }
  .complete_TicketContent {
    padding: 12.925px 30px;
    position: absolute;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 14.064px;
    z-index: 1;
  }
  .complete_TicketHead {
    color: #040404;

    font-family: Roboto;
    font-size: 22.182px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .complete_TicketNumber {
    color: var(--gold-prim, #fcc13f);
    font-family: Roboto;
    font-size: 44.364px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  .complete_TicketRegisteredContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10.548px;
  }
  .complete_TicketRegistered {
    color: #686666;

    font-family: Roboto;
    font-size: 18.485px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .complete_TicketCallHoursContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .complete_TicketRegisteredTime {
    color: #686666;

    font-family: Roboto;
    font-size: 18.485px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .complete_TicketCallHoursHead {
    color: #040404;

    font-family: Roboto;
    font-size: 22.182px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .complete_TicketCallHours {
    color: #f00;

    font-family: Roboto;
    font-size: 18.485px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .complete_LeftBottom {
    width: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    img {
      width: 188px;
      height: 57px;
    }
  }

  .complete_RightContainer {
    display: flex;
    width: 100%;
    max-width: 100%;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
  }
  .complete_RightTopBox {
    display: none;

    width: 100%;
    padding: 16px 32px;
    align-items: flex-start;
    gap: 64px;

    border-radius: none;
    background: var(--gold-prim, #fcc13f);
    box-shadow: none;
  }
  .complete_LeftTopBox {
    display: flex;

    order: 1;
    width: 100%;
    padding: 16px 32px;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: none;
    background: var(--gold-prim, #fcc13f);
    box-shadow: none;
  }
  .complete_TopBoxName {
    color: #070707;

    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }
  .complete_TopBoxNumbers {
    color: #070707;

    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .complete_TopBoxValue {
    color: #070707;

    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .complete_TopBoxTime {
    display: flex;
    align-items: right;
    justify-content: end;
    color: #070707;

    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .complete_MiddleText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 20px;
    gap: 8px;

    color: var(--Text-Grey-01, #868585);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .complete_Video {
    width: 100%;
    max-width: 586px;
    height: auto;
    flex-shrink: 0;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .complete_BottomAlert {
    display: flex;
    width: 100%;
    max-width: 559px;
    padding: 12px 16px;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    margin-top: 30px;
    box-sizing: border-box;

    background: #ff2424;
  }
  .complete_BottomLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 7px;
    align-self: stretch;
  }
  .complete_BottomHead {
    color: #fff;

    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  .complete_BottomDesc {
    color: #fff;

    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .complete_BottomRight {
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .complete_HeaderContainer {
    width: 100%;
    display: flex;
    padding: 16px 0px;

    gap: 0;
    align-self: stretch;
    border-bottom: 1px solid var(--Neutral-600, #eaebf0);
    background: #393939;
  }
  .complete_HeaderContent {
    display: flex;
    width: 100%;
    margin: 0 auto;
  }
  .complete_HeaderLogo {
    display: flex;
    justify-content: start;
    img {
      width: 116px;
      height: 35px;
    }
  }
  .complete_ContentContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
  }
  .complete_ContentContainer {
    margin-top: 35px;
    margin-bottom: 44px;
    display: flex;
    align-items: flex-start;
    gap: 5px;

    border-radius: 8px;
    border: 1px solid #ededed;
    background: #fefefe;
    box-shadow: 3px 4px 13px 1px rgba(0, 0, 0, 0.25);
    overflow: hidden;
  }

  .complete_LeftContainer {
    display: flex;
    width: 100%;
    min-width: 380px;
    max-width: 480px;
    flex-direction: column;
    align-items: flex-start;
    gap: 64px;

    flex: 0.5;
    height: 100%;
    min-height: 940px;
    max-height: 953px;

    background: url("../../../shared/images/left-bg.webp");
    background-size: cover;
  }
  .complete_LeftTop {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 25px;
  }
  .complete_Head {
    padding: 80px 0 0 0;
    color: #070707;

    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .complete_Desc {
    display: flex;
    flex-direction: column;
    padding: 16px 0px 0px 15px;
    color: #070707;

    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .complete_TicketContainer {
    display: flex;
    padding: 12.925px 5px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12.925px;
    align-self: stretch;
  }
  .complete_TicketImg {
    position: relative;
    z-index: 0;
    img {
      max-width: 370.151px;
      height: auto;
    }
  }
  .complete_TicketContent {
    padding: 12.925px 15px;
    position: absolute;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 14.064px;
    z-index: 1;
  }
  .complete_TicketHead {
    color: #040404;

    font-family: Roboto;
    font-size: 35.159px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .complete_TicketNumber {
    color: #fcc13f;

    font-family: Roboto;
    font-size: 70.318px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  .complete_TicketRegisteredContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10.548px;
  }
  .complete_TicketRegistered {
    color: #686666;

    font-family: Roboto;
    font-size: 28.127px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .complete_TicketCallHoursContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .complete_TicketRegisteredTime {
    color: #686666;

    font-family: Roboto;
    font-size: 28.127px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .complete_TicketCallHoursHead {
    color: #040404;

    font-family: Roboto;
    font-size: 35.159px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .complete_TicketCallHours {
    color: #f00;

    font-family: Roboto;
    font-size: 38.774px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .complete_LeftBottom {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    img {
      width: 188px;
      height: 57px;
    }
  }

  .complete_RightContainer {
    display: flex;
    width: 100%;
    max-width: 100%;
    padding: 40px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 36px;
    align-self: stretch;
  }
  .complete_RightTopBox {
    display: flex;
    padding: 16px 32px;
    align-items: flex-start;
    gap: 64px;

    border-radius: 8px;
    background: var(--gold-prim, #fcc13f);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    animation-duration: 2s;
  }
  .complete_TopBoxName {
    color: #070707;

    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px; /* 141.667% */
  }
  .complete_TopBoxNumbers {
    color: #070707;

    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .complete_TopBoxValue {
    color: #070707;

    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .complete_TopBoxTime {
    display: flex;
    align-items: right;
    justify-content: end;
    color: #070707;

    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .complete_MiddleText {
    display: flex;
    flex-direction: column;
    justify-content: center;

    gap: 16px;

    color: var(--Text-Grey-01, #868585);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .complete_Video {
    width: 100%;
    max-width: 586px;
    height: auto;
    flex-shrink: 0;
    box-sizing: border-box;
  }
  .complete_BottomAlert {
    display: flex;
    width: 100%;
    max-width: 559px;
    padding: 24px 16px 24px 30px;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    box-sizing: border-box;

    background: #ff2424;
  }
  .complete_BottomLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 7px;
    align-self: stretch;
  }
  .complete_BottomHead {
    color: #fff;

    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  .complete_BottomDesc {
    color: #fff;

    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .complete_BottomRight {
    display: flex;
    padding: 32px 0.5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
  }
  .complete_LeftTopBox {
    display: none;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .complete_HeaderContainer {
    width: 100%;
    display: flex;
    padding: 16px 0;

    gap: 10px;
    align-self: stretch;
    border-bottom: 1px solid var(--Neutral-600, #eaebf0);
    background: #393939;
  }
  .complete_HeaderContent {
    display: flex;
    width: 100%;
    max-width: 1268px;
    margin: 0 auto;
  }
  .complete_HeaderLogo {
    display: flex;
    justify-content: start;
    img {
      width: 116px;
      height: 35px;
    }
  }
  .complete_ContentContainer {
    display: flex;
    width: 100%;
    max-width: 1268px;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
  }
  .complete_ContentContainer {
    margin-top: 35px;
    margin-bottom: 44px;
    display: flex;
    align-items: flex-start;
    gap: 32px;


    border-radius: 8px;
    border: 1px solid #ededed;
    background: #fefefe;
    box-shadow: 3px 4px 13px 1px rgba(0, 0, 0, 0.25);
    overflow: hidden;
  }

  .complete_LeftContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 64px;

    flex: 0.5;
    height: 100%;
    min-height: 953px;
    max-height: 953px;

    background: url("../../../shared/images/left-bg.webp");
    background-size: cover;
  }
  .complete_LeftTop {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 15px;
  }
  .complete_Head {
    padding: 80px 0 0 0;
    color: #070707;

    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .complete_Desc {
    display: flex;
    flex-direction: column;
    padding: 16px 0px 0px 15px;
    color: #070707;

    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .complete_TicketContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12.925px;
    align-self: stretch;
  }
  .complete_TicketImg {
    position: relative;
    z-index: 0;

    img {
      width: 420.151px;
      height: 414.877px;
    }
  }
  .complete_TicketContent {
    padding: 12.925px 30px;
    position: absolute;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 14.064px;
    z-index: 1;
  }
  .complete_TicketHead {
    color: #040404;

    font-family: Roboto;
    font-size: 35.159px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .complete_TicketNumber {
    color: #fcc13f;

    font-family: Roboto;
    font-size: 70.318px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  .complete_TicketRegisteredContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10.548px;
  }
  .complete_TicketRegistered {
    color: #686666;

    font-family: Roboto;
    font-size: 28.127px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .complete_TicketCallHoursContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .complete_TicketRegisteredTime {
    color: #686666;

    font-family: Roboto;
    font-size: 28.127px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .complete_TicketCallHoursHead {
    color: #040404;

    font-family: Roboto;
    font-size: 35.159px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .complete_TicketCallHours {
    color: #f00;

    font-family: Roboto;
    font-size: 38.774px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .complete_LeftBottom {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    img {
      width: 188px;
      height: 57px;
    }
  }

  .complete_RightContainer {
    display: flex;
    width: 100%;
    max-width: 100%;
    padding: 40px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 36px;
    align-self: stretch;
  }
  .complete_RightTopBox {
    display: flex;
    padding: 16px 32px;
    align-items: flex-start;
    gap: 64px;

    border-radius: 8px;
    background: var(--gold-prim, #fcc13f);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    animation-duration: 2s;
  }
  .complete_TopBoxName {
    color: #070707;

    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px; /* 141.667% */
  }
  .complete_TopBoxNumbers {
    color: #070707;

    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .complete_TopBoxValue {
    color: #070707;

    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .complete_TopBoxTime {
    display: flex;
    align-items: right;
    justify-content: end;
    color: #070707;

    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .complete_MiddleText {
    display: flex;
    flex-direction: column;
    justify-content: center;

    gap: 16px;

    color: var(--Text-Grey-01, #868585);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .complete_Video {
    width: 100%;
    max-width: 586px;
    height: auto;
    flex-shrink: 0;
    box-sizing: border-box;
  }
  .complete_BottomAlert {
    display: flex;
    width: 100%;
    max-width: 559px;
    padding: 24px 16px 24px 30px;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    box-sizing: border-box;

    background: #ff2424;
  }
  .complete_BottomLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 7px;
    align-self: stretch;
  }
  .complete_BottomHead {
    color: #fff;

    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  .complete_BottomDesc {
    color: #fff;

    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .complete_BottomRight {
    display: flex;
    padding: 32px 0.5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
  }
  .complete_LeftTopBox {
    display: none;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .complete_HeaderContainer {
    width: 100%;
    display: flex;
    padding: 16px 0px;

    gap: 10px;
    align-self: stretch;
    border-bottom: 1px solid var(--Neutral-600, #eaebf0);
    background: #393939;
  }
  .complete_HeaderContent {
    display: flex;
    width: 100%;
    max-width: 1268px;
    margin: 0 auto;
  }
  .complete_HeaderLogo {
    display: flex;
    justify-content: start;
    img {
      width: 116px;
      height: 35px;
    }
  }
  .complete_ContentContainer {
    display: flex;
    width: 100%;
    max-width: 1268px;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
  }
  .complete_ContentContainer {
    height: 100%;
    max-height: 100%;
    margin-top: 35px;
    margin-bottom: 44px;
    display: flex;
    align-items: flex-start;
    gap: 32px;

    border-radius: 8px;
    border: 1px solid #ededed;
    background: #fefefe;
    box-shadow: 3px 4px 13px 1px rgba(0, 0, 0, 0.25);
    overflow: hidden;
  }

  .complete_LeftContainer {
    display: flex;
    flex: 0.5;
    width: 100%;
    max-width: 618px;
    height: 100%;
    min-height: 953px;
    max-height: 953px;

    flex-direction: column;
    align-items: flex-start;
    gap: 64px;

    background: url("../../../shared/images/left-bg.webp");
    background-size: cover;
  }
  .complete_LeftTop {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 90px;
  }
  .complete_Head {
    padding: 80px 0px 0px 15px;
    color: #070707;

    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .complete_Desc {
    display: flex;
    flex-direction: column;
    padding: 16px 0px 0px 15px;
    color: #070707;

    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .complete_TicketContainer {
    display: flex;
    padding: 12.925px 90px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12.925px;
    align-self: stretch;
  }
  .complete_TicketImg {
    position: relative;
    z-index: 0;
    img {
      width: 410.151px;
      height: 414.877px;
    }
  }
  .complete_TicketContent {
    padding: 12.925px 30px;
    position: absolute;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 14.064px;
    z-index: 1;
  }
  .complete_TicketHead {
    color: #040404;

    font-family: Roboto;
    font-size: 35.159px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .complete_TicketNumber {
    color: #fcc13f;

    font-family: Roboto;
    font-size: 70.318px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  .complete_TicketRegisteredContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10.548px;
  }
  .complete_TicketRegistered {
    color: #686666;

    font-family: Roboto;
    font-size: 28.127px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .complete_TicketCallHoursContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .complete_TicketRegisteredTime {
    color: #686666;

    font-family: Roboto;
    font-size: 28.127px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .complete_TicketCallHoursHead {
    color: #040404;

    font-family: Roboto;
    font-size: 35.159px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .complete_TicketCallHours {
    color: #f00;

    font-family: Roboto;
    font-size: 38.774px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .complete_LeftBottom {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    img {
      width: 188px;
      height: 57px;
    }
  }

  .complete_RightContainer {
    display: flex;
    flex: 0.5;
    width: 100%;
    max-width: 100%;
    padding: 40px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 36px;
    align-self: stretch;
  }
  .complete_RightTopBox {
    display: flex;
    padding: 16px 32px;
    align-items: flex-start;
    gap: 64px;

    border-radius: 8px;
    background: var(--gold-prim, #fcc13f);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    animation-duration: 2s;
  }
  .complete_TopBoxName {
    color: #070707;

    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px; /* 141.667% */
  }
  .complete_TopBoxNumbers {
    color: #070707;

    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .complete_TopBoxValue {
    color: #070707;

    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .complete_TopBoxTime {
    display: flex;
    align-items: right;
    justify-content: end;
    color: #070707;

    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .complete_MiddleText {
    display: flex;
    flex-direction: column;
    justify-content: center;

    gap: 16px;

    color: var(--Text-Grey-01, #868585);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .complete_Video {
    width: 100%;
    max-width: 586px;
    height: auto;
    flex-shrink: 0;
    box-sizing: border-box;
  }
  .complete_BottomAlert {
    display: flex;
    width: 100%;
    max-width: 559px;
    padding: 24px 16px 24px 30px;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    box-sizing: border-box;

    background: #ff2424;
  }
  .complete_BottomLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 7px;
    align-self: stretch;
  }
  .complete_BottomHead {
    color: #fff;

    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  .complete_BottomDesc {
    color: #fff;

    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .complete_BottomRight {
    display: flex;
    padding: 32px 0.5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
  }
  .complete_LeftTopBox {
    display: none;
  }
}

.slideLeft {
  animation: 0.2s slideLeft;
}
@keyframes slideLeft {
  from {
    margin-left: 100%;
  }
  to {
    margin-left: 0%;
  }
}
.bounce7 {
  animation-name: bounce7;
  animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
}
@keyframes bounce7 {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-100px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-7px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}
