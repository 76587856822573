.contacts {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 64px;
  padding: 30px 0;
  margin: 20px 0;

  .left_block {
    max-width: 791.23px;
    max-height: 617px;

    img {
      max-width: 100%;
      height: auto;
    }
  }
  .right_block {
    max-width: 441px;
  }
}

@media (max-width: 1440px) {
  .contacts {
    gap: 30px;
  }
}
@media (max-width: 1280px) {
  .contacts {
    padding: 15px 0;

    .left_block,
    .right_block {
      max-width: 100%;
      max-height: 100%;
      flex: 0.5;
    }
  }
}
@media (max-width: 767.9px) {
  .contacts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;

    .left_block {
      width: 100%;
      max-width: 100%;

      img {
        max-width: 200px;
      }

      .gift_mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        padding: 0 30px;

        h3 {
          font-size: 24px;
          font-weight: 700;
          line-height: 31px;
          color: var(--light-black);

          margin-bottom: 10px;
        }
        h4 {
          span {
            font-size: 50px;
            font-weight: 700;
            line-height: 50px;

            background: var(--gold-prim);

            &:first-child {
              font-size: 20px;
              font-weight: 700;
              line-height: 28px;
            }
          }
        }
      }
    }
    .right_block {
      max-width: 100%;
      padding: 0 30px;
    }
  }
}