.documents {
  padding: 30px 0;

  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__title {
      font-size: 36px;
      font-weight: 700;
      line-height: 40px;
      letter-spacing: -0.02em;
      margin-bottom: 16px;
      color: var(--dark-blue);
    }
    &__desc {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.1px;
      color: var(--light-blue);
      text-align: center;
    }
  }
  .content {
    width: 100%;
    max-width: 1040px;
    display: flex;
    justify-content: center;
    margin-top: 64px;

    .accordion {
      width: 100%;
    }
  }
}

@media (max-width: 1200px) {
  .documents {
    padding: 15px;

    .content {
      width: 900px;
      margin-top: 30px;
    }
  }
}
@media (max-width: 992px) {
  .documents {
    .content {
      width: 100%;
    }
  }
}
@media (max-width: 655px) {
  .documents {
    .header {
      &__title {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.02em;
      }
    }
    .accordion {
      div {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.1px;
        color: #272D37;
      }
    }
  }
}