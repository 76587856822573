.card {
  padding: 20px 0;

  .package {
    display: block;
    max-width: 372px;
    height: 32px;
    margin-bottom: 20px;

    font-size: 14px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: var(--black);

    span {
      padding: 10px;
      background: var(--white1);
    }
  }
  .image {
    max-width: 372px;
    max-height: 164px;

    img {
      max-width: 100%;
      height: auto;
    }
  }
  .income {
    margin-top: 20px;

    font-size: 36px;
    font-weight: 600;
    line-height: 47px;
    letter-spacing: 0;
    color: var(--gold-prim);

    span {
      font-size: 12px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.02em;
      color: var(--black);
      opacity: 0.5;
    }
  }
  .desc {
    max-width: 372px;
    margin-top: 12px;

    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: var(--black);
    opacity: 0.7;
  }
  .price {
    margin-top: 30px;

    &__title {
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      color: var(--light-blue);
      margin-bottom: 10px;
    }
    &__values {
      display: flex;
      align-items: center;
    }
    &__discount {
      font-size: 30px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0;
      color: var(--black1);
    }
    &__full {
      padding-top: 5px;
      margin-left: 10px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0;
      color: var(--light-blue);
      text-decoration: line-through;
    }
  }

  button {
    width: 100%;
    margin-top: 20px;
  }
}
.cards {
  display: flex;
  gap: 100px;

  .card {
    @extend .card;
  }
}
.cards_slider {
  max-width: 400px;

  .card {
    @extend .card;

    max-width: 343px;
    text-align: start;

    .income {
      font-size: 36px;
      line-height: 36px;
    }
    .desc {
      font-size: 18px;
      line-height: 24px;
    }
    .price {
      &__title {
        font-size: 18px;
        line-height: 24px;
      }
      &__full {
        font-size: 16px;
        line-height: 24px;
      }
      &__discount {
        font-size: 30px;
        line-height: 24px;
      }
    }
  }
}

@media (max-width: 1440px) {
  .cards {
    gap: 30px;

    .card {
      .income {
        font-size: 2.5vw;
        line-height: 3vw;
      }
      .desc {
        font-size: 1.4vw;
        line-height: 1.4vw;
      }
      .price {
        margin-top: 15px;
        &__title {
          font-size: 1.4vw;
          line-height: 1.4vw;
        }
        &__discount {
          font-size: 2vw;
          line-height: 2.5vw;
        }
      }
    }
  }
}
@media (max-width: 500px) {
  .cards_slider {
    max-width: 360px;

    .card {
      max-width: 300px;
    }
  }
}
@media (max-width: 500px) {
  .cards_slider {
    max-width: 300px;

    .card {
      max-width: 250px;
    }
  }
}